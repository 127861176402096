import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Footer from "../components/Footer"

const Page = (Life) => (

  <Layout>
    <div className="contentWrapper">
    <div className="life">
            <StaticImage className="LifeImage" src="../images/fam.jpg" alt="My family" />
            <p>Before I’m a designer, I’m a husband and a father. Paige (le wife) and I are raising two spunky, energetic little girls, LJ and Baker.</p>
          </div>
          <div className="life flip">
             <p>I’m a curious consumer. I enjoy listening to and reading about design, ancient cultures, and fictional stories. My guilty pleasures are the inter-connected worlds of Marvel and DC comics..</p>
            <StaticImage className="LifeImage"  src="../images/books.jpg" alt="My favorite design books"/>
          </div>
          <div className="life">
          <StaticImage className="LifeImage"  src="../images/woodworking_color.jpg" alt="Personal Projects"/>
            <p>I enjoy working with my hands and craft is something I appreciate across different mediums. I recently built a small table for my front porch, and am refurbishing an antique cabinet.</p>
          </div>

          <Footer/>
    </div>
   
 </Layout> 
)

export const Head = (Life) => <Seo title="Life" />

export default Page

